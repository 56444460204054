// Colors
$white: #ffffff;
$black: #000000;
$golden: #C8A552;

//Fonts
$font: 'Noto Sans', sans-serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 62.5%;

// Base
$font-size-base: 1.8rem;
$line-height-base: 1.6 !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Headers
$h1-font-size: 60px !default;
$h2-font-size: 44px !default;
$h3-font-size: 24px !default;
$h4-font-size: 2.2rem !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;