img {
    width: 100%;
}

blockquote {
    font-weight: 300;
    margin: 0;
    @include media('>=desktop') {
        margin: 2.8rem;
    }
}

body {

	&.scrolled {

		.hero {
			.site-links {
				padding: 25px;
			}

			.site-logo {

				img {
					height: 30vh;

					@include media("<tablet") {
						left: 10px;
						width: 25px;
						height: auto;
						transform: translate(0%, -50%);
					}
				}

			}
		}

	}

}

.main {

    scroll-behavior: smooth;

    @include media(">=tablet") {
        height: 100vh;
        scroll-snap-points-y: repeat(100vh);
        scroll-snap-type: y mandatory;
    }
}

.section {
    position: relative;
    height: auto;
    overflow: hidden;

    img {
        object-fit: cover;
        height: 100%;
    }

    @include media(">=tablet") {
        scroll-snap-align: start;
        height: 864px;
        height: 80vh;
    }
    
}

.hero {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

.btn {
    border: 2px solid $black;
    text-decoration: none;
    padding: 2px 73px 10px;
    font-size: 40px;

    &:hover {
        background-color: $black;
        color: $white;
    }

    &-alt {
        border-color: $white;
        padding: 4px 36px 6px;

        &:hover {
            background-color: $white;
            color: $black;
        }
    }

    &.notice {
        background-color: $white;
        color: $black;
        padding: 4px 8px 6px;

        &:hover, &:focus {
            background-color: $black;
            color: $white;
        }
    }
}

.gallery {
    display: flex;

    @include media("<=desktop") {
        flex-direction: column;
    }
}

// Columns

.col-4 {
    width: 40%;
    position: relative;
}
.col-6 {
    width: 50%;
}
.col-8 {
    width: 80%;
}

.col {
    @include media("<=desktop") {
        width: 100%;
        height: 50%;
    }
}

// Gradient overlays

.overflow {
    &::before {
        content: "";
        background: linear-gradient(180deg, rgba(0,0,0,.95) 0%, rgba(0,0,0,.5) 25%, rgba(0,0,0,.25) 50%, rgba(0,0,0,0) 100%);
        position: absolute;
        width: 100%;
        height: 60%;
    }
    &-right {
        &::before {
            background: linear-gradient(270deg, rgba(0,0,0,.95) 0%, rgba(0,0,0,.5) 25%, rgba(0,0,0,.25) 50%, rgba(0,0,0,0) 100%);
            width: 40%;
            height: 100%;
            right: 0;
        }
    }
}

.quote-holder {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px 32px;
    font-size: 18px;
    @include media(">=desktop") {
        font-size: .75rem;
    }
    @media screen and (max-height: 860px) {
        font-size: .55rem;
        blockquote {
            margin: 0;
        }
    }
    @media (min-width: 1240px) and (max-height: 1000px) {
        font-size: .75rem;
    }
}

.text-block {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    position: relative;

    .text-holder {
        max-width: 1144px;
        margin: 60px auto 60px;
        padding-left: 32px;
        padding-right: 32px;
    }

    @include media(">=tablet") {

        .text-holder {
            position: absolute;
            margin: 0 auto 120px;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }

    @include media(">=x-widescreen") {
        font-size: 24px;

        .text-holder {
            margin: 0 auto 60px;
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}

// Header

header {
	background: rgb(0,0,0);
	background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    color: $white;
    position: relative;
    &.section {
        height: auto;
        overflow: visible;
        z-index: 9999;
    }

    @include media(">=tablet") {
        &.sticky {
            position: fixed;
            width: 100%;
    
            .site-links {
                padding: 10px 32px 20px;
                a {
                    font-size: 16px;
                }
            }
        }
    }
    
}

.site-links {
    padding: 60px 100px 60px;
    width: 100%;
    text-align: right;
	transition: all .3s ease-in-out;

    a {
        text-decoration: none;
        font-size: 20px;
        margin-left: 60px;
    }

    @include media("<=tablet") {
        text-align: center;
        padding: 20px 32px;

        a {
            margin-left: 0;
            margin-bottom: .25rem;
            display: block;
        }
    }
}

.site-logo {
    h1 {
		display: none;
    }

	img {
		transition: all .3s ease-in-out;
		position: fixed;
		top: 50%;
		transform: translate(0%, -50%);
		left: 40px;
		width: auto;
		height: 70vh;
		object-fit: contain;

		@include media("<tablet") {
			left: 50%;
			transform: translate(-50%, -50%);
			width: 50px;
			height: auto;
		}
	}
}

// Footer

footer {
    background: $white;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    padding-top: 80px;
    &.section {
        height: auto;
        overflow: visible;
        scroll-snap-align: end;
        padding-bottom: 60px;
    }
}

.footer-top {
    display: flex;
    justify-content: space-between;
    margin: 80px 140px;
    text-align: center;
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    ul {
        padding-left: 0;
        li {
            display: block;
        }
    }
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 80px 140px;
}

@include media('<=desktop') {
    .footer-top, .footer-bottom {
        flex-direction: column;
        margin: 80px 32px;
        gap: 60px;
    }
}

.socials {
    .icons {
        content: '';
        font-size: 0;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        width: 44px;
        height: 44px;

        &.ig {
            background-image: url('/assets/img/instagram-puur-stijl-soest.svg');
        }
        &.li {
            background-image: url('/assets/img/linkedin-puur-stijl-soest.svg');
        }
        &.fb {
            background-image: url('/assets/img/facebook-puur-stijl-soest.svg');
        }
    }
}

.footer-logo {
    width: 330px;
    height: auto;
}

.services {
    img {
        width: 38px;
        height: auto;
    }
}

// pricing

.pricing {
    background-image: url('/assets/img/kapsalon-puur-stijl-soest.jpg');
    background-size: cover;
    height: auto;

    &::before {
        content: '';
        background-color: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(10px);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .text-holder {
        position: relative;
        z-index: 2;
        max-width: 1144px;
        margin: 0 auto;
        text-align: center;
        padding: 80px 32px 100px;

        @include media("<=desktop") {
            padding: 40px 32px 80px;
        }
    }

    .pricing-block {
        column-count: 2;
        column-gap: 2.85rem;
        text-align: left;
        margin-bottom: 60px;

        @include media("<=desktop") {
            column-count: 1;
            margin-bottom: 25px;
        }
    }

    .pricing-section {
        font-size: .556rem;
        margin-bottom: 30px;

        @include media(">=desktop") {
            margin-bottom: 60px;
        }

    }

    ul {
        padding-left: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid $golden;
    }

    li {
        display: flex;
        justify-content: space-between;
    }
}

// bg text images

.bg-img-01 {
    background-image: url('/assets/img/zand-duinen-soest-puur-stijl.jpg');
    background-size: cover;
}

.bg-img-02 {
    background-image: url('/assets/img/schuim-puur-stijl-soest.jpg');
    background-size: cover;
}