html {
	box-sizing: border-box;
	font-size: 62.5%;
    color: $black;
	font: #{$font-size-base}/#{$line-height-base} $font;
	min-width: $base-min-width;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
	background: $white;
}

// adaptive images
img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

ul[class] {
	// @extend %listreset;
	margin-top: 0;
	list-style-type: none;
	padding-left: 0;
}
