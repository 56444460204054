// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
	font-family: $font;
	font-weight: 700;
	margin: 0 0 0.5em;
	color: $black;
}

h1, .h1 {
	font-size: $h1-font-size;
	line-height: 1;
	margin-bottom: 0;
	font-weight: 900;

	@include media('screen', '<tablet') {
		font-size: 4rem;
	}

	@include media('screen', '>=desktop') {
		font-size: 8rem;
	}

	&.font-secondary  {
		font-size: 5.8rem;
		line-height: 1.28;

		@include media('screen', '>=desktop') {
			font-size: 5.8rem;
		}

		@include media('screen', '>=x-widescreen') {
			font-size: 12rem;
		}
	}

	
}

h2, .h2 {
	font-size: $h2-font-size;
	font-weight: 100;
	letter-spacing: 4px;
	text-transform: uppercase;

	@include media('screen', '>=desktop') {
		font-size: 3.35rem;
	}
}

h3, .h3 {
	font-size: $h3-font-size;
	line-height: 1;
	letter-spacing: 1px;
	text-transform: uppercase;
}

h4, .h4 {
	font-size: $h4-font-size;
	text-transform: uppercase;
	font-weight: 900;
	line-height: 1;

	@include media('screen', '>=desktop') {
		font-size: 4rem;
	}

	&.font-secondary  {

		@include media('screen', '>=x-widescreen') {
			font-size: 5.2rem;
		}
	}
}

h5, .h5 {
	font-size: $h5-font-size;
}

h6, .h6 {
	font-size: $h6-font-size;
}

p {
	margin: 0 0 1em;
}

a {
	color: inherit;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}
